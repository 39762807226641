import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["src"];

  connect() {
    console.log('copy, over...')
  };

  copy(evt) {
    evt.preventDefault();
    const button = evt.currentTarget;
    const text = button.innerText;

    navigator.clipboard.writeText(this.srcTarget.value);
    button.classList.add("btn-success");
    button.innerText = 'Copied!'

    setTimeout(() => {
      button.innerText = text;
      button.classList.remove("btn-success")
    }, 2000)
  }
}
